<template>
    <b-card class="rounded-0" :class="getMarginLeftClass()">
        <div class="d-flex">
            <p class="card-text mr-auto">
                <span :class="getIsOpenClass(item)">
                    <font-awesome-icon v-if="item.hasChildren" icon="chevron-right" />
                    <span v-if="showSortOrder">{{ item.sortOrder }} - </span><span :class="{ 'font-weight-bold': item.type === 2 }">
                        <font-awesome-icon v-if="item.menuIcon" :icon="item.menuIcon" />
                        {{ item.name }}
                    </span>
                </span>
            </p>

            <div>
                <div class="d-inline-flex justify-content-between" style="width: 85px">
                    <div :title="getIconTitleForArchive(item.type)"
                         v-b-tooltip.hover>
                        <font-awesome-icon class="icon-gray"
                                           :icon="getIconForArchive(item.type)" />
                    </div>
                </div>

                <div class="d-inline-flex justify-content-between" style="width: 45px">
                    <div title="Ändra"
                         v-b-tooltip.hover
                         @click="edit($event, item)">
                        <font-awesome-icon class="text-secondary"
                                           icon="pen-square"
                                           size="lg" />
                    </div>
                    <div v-if="item.type !== 2"
                         title="Lägg till underpost"
                         v-b-tooltip.hover
                         @click="add($event, item)">
                        <font-awesome-icon class="text-primary"
                                           icon="plus-square"
                                           size="lg" />
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            item: Object,
            showSortOrder: Boolean,
            marginLeft: {
                type: String,
                required: false,
                default: ''
            },
            showTypeDescription: Boolean
        },
        computed: {
            ...mapState({
                types: (state) => state.archive.types
            })
        },
        methods: {
            getIconForArchive(type) {
                return this.types.find((x) => x.value === type).icon;
            },
            getIconTitleForArchive(type) {
                return this.types.find((x) => x.value === type).text;
            },
            getMarginLeftClass() {
                return this.marginLeft ? `ml-${this.marginLeft}` : '';
            },
            getIsOpenClass(item) {
                return item.isOpen ? 'isOpen' : 'isClosed';
            },
            add(event, item) {
                event.stopPropagation();
                this.$emit('event', { action: 'add', item: { parentId: item.id } });
            },
            edit(event, item) {
                event.stopPropagation();
                this.$emit('event', { action: 'edit', item: item });
            }
        }
    };
</script>

<style scoped>
    .icon-gray {
        color: #929292;
    }

    .card-text {
        margin-bottom: 0.5rem;
    }

    .ml-10 {
        margin-left: 6rem;
    }

    .card {
        cursor: pointer;
        margin-bottom: -1px;
        border-color: #b1b1b1;
    }

        .card:hover,
        .card:active,
        .card:focus {
            background-color: #efefef;
        }

    .card-body {
        padding: 10px 15px;
        padding-bottom: 0px;
    }
</style>
