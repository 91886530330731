<template>
  <div>
    <b-list-group v-for="customer in customers" :key="customer.id">
      <b-list-group-item
        role="button"
        @click="copy(customer.id, customer.name, customer.customerNo)"
      >
        {{ customer.name }} - {{ customer.customerNo }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import { get, post } from '@/helpers/api';
import { mapState } from 'vuex';

export default {
  name: 'Copy',
  data() {
    return {
      customers: []
    };
  },
  computed: {
    ...mapState({
      selectedCustomerId: (state) => state.user.selectedCustomer.id,
      selectedCustomerNo: (state) => state.user.selectedCustomer.customerNo,
      selectedCustomerName: (state) => state.user.selectedCustomer.name
    })
  },
  async mounted() {
    // Fetch data.
    this.getData();
  },
  methods: {
    copy(id, customerName, customerNo) {
      // Confirm.
      this.$bvModal
        .msgBoxConfirm(
          `Är du säker på att du vill kopiera från ${customerName} (${customerNo}) till ${this.selectedCustomerName} (${this.selectedCustomerNo})?`,
          {
            title: '',
            size: 'sm',
            cancelVariant: 'primary',
            cancelTitle: 'Nej',
            okTitle: 'Ja',
            okVariant: 'danger',
            footerClass: 'p-2 justify-content-between',
            hideHeaderClose: false,
            centered: true
          }
        )
        .then((confirmed) => {
          if (confirmed) {
            this.copyArchives(id);
            this.reset();
          }
        })
        .catch(() => {
          // An error occurred
          this.isSubmitting = true;
        });
    },
    async copyArchives(id) {
      await post('archive', 'copyarchives', {
        customertemplateid: id,
        customerid: this.selectedCustomerId
      })
        .then((x) => {
          this.$emit('event', { action: 'copied' });
        })
        .catch((x) => {
          // error handling.
        });
    },
    async getData() {
      await get('Customer', 'GetAllTemplate')
        .then((x) => (this.customers = x.data))
        .catch((x) => {
          // error handling.
        });
    }
  }
};
</script>

<style>
.list-group-item:hover,
.list-group-item:active,
.list-group-item:focus {
  background-color: #efefef;
}
</style>
